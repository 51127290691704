import React from "react"
import PropTypes from "prop-types"
import Fade from "@mui/material/Fade"
import { css } from "@emotion/react"

const VoiceInfo = ({ isSubmiting }) => {
  return (
    <Fade in={!isSubmiting}>
      <div
        css={css`
          padding-top: 8px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 14px;
          color: white;
        `}
      >
        Hold to start
        <span
          css={css`
            color: lightgray;
            font-size: 12 px;
          `}
        >
          1 min max
          {/* Please record within 1 min */}
        </span>
      </div>
    </Fade>
  )
}

VoiceInfo.propTypes = {
  isSubmiting: PropTypes.bool,
}

export default VoiceInfo
