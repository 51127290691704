import { Global, css } from "@emotion/react"
import { useRef, useEffect, useReducer, useCallback } from "react"
import fscreen from "fscreen"

import Main from "./components/pages/main/main"
import Back from "./components/back"
import ControlPanel from "./components/controlPanel"
import Version from "./components/version"
import Chat from "./components/pages/chat/chat"
import { socketSend } from "./helpers/socket"
import { findOption } from "./helpers/findOption"
import sayJoke from "./helpers/sayJoke"
import { initialState } from "./state/initialState"
import reducer from "./reducer"
import bg from "./images/background.png"
import { gapi } from "gapi-script"
import Login from "./components/login"

export default function App({ socket, socketEnvLink }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  socket.onopen = function (e) {
    console.info("Connected to: ", socketEnvLink)
    dispatch({ type: "SOCKET_CONNECTED_TOGGLE" })
  }
  socket.onerror = function (error) {
    console.info(`Server error: ${error.message}`)
    // dispatch({ type: "SOCKET_CONNECTED_TOGGLE" })
  }

  const {
    view,
    webSocketIsConnected,
    optionsList,
    isFirstRender,
    inFullscreenMode,
    userResponseTimeout,
    welcomeSpeechInterval,
    currentWelcomeSpeech,
    isTestMode,
    isSayJoke,
    access_token,
    showChatButton,
    showLoginButton,
    showVoiceButton,
    clientId,
  } = state

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId,
        scope: "https://www.googleapis.com/auth/dialogflow",
        redirectUri: "http://localhost:1234/authorize/",
      })
    }
    gapi.load("client:auth2", start)
    // const accessToken = gapi.auth.getToken().access_token
    //console.log(accessToken)
  }, [])

  const handleFullscreenChange = useCallback((e) => {
    let change = ""
    if (fscreen.fullscreenElement !== null) {
      change = "Entered fullscreen mode"
      dispatch({ type: "SET_FULLSCREEN_MODE", payload: true })
    } else {
      change = "Exited fullscreen mode"
      dispatch({ type: "SET_FULLSCREEN_MODE", payload: false })
    }
    console.log(change, e)
  }, [])

  const handleFullscreenError = useCallback(
    (e) => console.log("Fullscreen Error", e),
    []
  )

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener(
        "fullscreenchange",
        handleFullscreenChange,
        false
      )
      fscreen.addEventListener("fullscreenerror", handleFullscreenError, false)
      return () => {
        fscreen.removeEventListener("fullscreenchange", handleFullscreenChange)
        fscreen.removeEventListener("fullscreenerror", handleFullscreenError)
      }
    }
  })

  useEffect(() => {
    const welcomeSpeech = findOption(state.currentWelcomeSpeech)
    if (!isFirstRender) {
      if (view === "main") {
        socketSend(socket, welcomeSpeech)
      }
    }
  }, [currentWelcomeSpeech])

  useEffect(() => {
    if (view === "main") {
      const welcomeSpeechSetInterval = setInterval(() => {
        dispatch({ type: "NEXT_WELCOME_SPEECH" })
      }, welcomeSpeechInterval) // 60000
      return () => clearInterval(welcomeSpeechSetInterval)
    }

    if (view === "options") {
      dispatch({ type: "SET_WAITING_USER_RESPONSE_TIMER" })
      const responseTimer = setTimeout(
        () => dispatch({ type: "FINISH_WAITING_USER_RESPONSE" }),
        userResponseTimeout
      )
      return () => responseTimer
    }
  }, [view])

  const appElement = useRef(null)

  const toggleFullscreen = useCallback(() => {
    if (inFullscreenMode) {
      fscreen.exitFullscreen()
    } else {
      fscreen.requestFullscreen(appElement.current)
    }
  }, [inFullscreenMode])

  useEffect(() => {
    dispatch({ type: "SET_FIRST_RENDER" })
    if (!isFirstRender) {
      webSocketIsConnected
        ? dispatch({ type: "SET_VIEW", payload: "main" })
        : console.log("NO CONNECTION WITH SERVER!")
    }
  }, [webSocketIsConnected])

  useEffect(() => {
    if (isSayJoke) {
      if (view === "main") {
        sayJoke(socket)
      }
    }
  }, [isSayJoke])

  const emptyList = () =>
    setTimeout(() => dispatch({ type: "SET_VIEW", payload: "main" }), 2000)

  const onEndSession = () => {
    const goodByePhrase = findOption(23)
    socketSend(socket, goodByePhrase)
    dispatch({ type: "END_SESSION" })
    setTimeout(() => dispatch({ type: "SAY_JOKE" }), 8000)
    console.log("End Session")
  }

  const onSuccess = (access_token) => {
    console.log(access_token)
    dispatch({ type: "SAVE_TOKEN", payload: access_token })
  }
  const onFailure = () => dispatch({ type: "UNABLE_TO_GET_TOKEN" })
  return (
    <div
      css={css`
        max-width: 100vh;
        min-width: 100vh;
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(${bg}) no-repeat center center fixed;
        background-size: cover;
      `}
      ref={appElement}
    >
      {access_token === "" && !inFullscreenMode && showLoginButton && (
        <Login
          onSuccess={onSuccess}
          onFailure={onFailure}
          clientId={clientId}
        />
      )}

      {isTestMode && <Version />}
      <Global
        styles={css`
          body {
            font-family: system-ui, -apple-system, "Segoe UI", Roboto,
              "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
              sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
              "Segoe UI Symbol", "Noto Color Emoji";
            margin: 0;
            padding: 0;
            min-height: 100vh;
            /* max-width: 100vh; */
            color: white;
          }
          #root {
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          html {
            background: url(${bg}) no-repeat center center fixed;
            background-size: cover;
          }
        `}
      />
      <ControlPanel
        toggleFullscreen={toggleFullscreen}
        inFullscreenMode={inFullscreenMode}
        view={view}
        showChatButton={showChatButton}
        showChat={() => dispatch({ type: "SET_VIEW", payload: "chat" })}
      />
      <Main
        view={view}
        optionsList={optionsList}
        emptyList={emptyList}
        socket={socket}
        dispatch={dispatch}
        isTestMode={isTestMode}
      />
      {view === "chat" && (
        <Chat
          showVoiceButton={showVoiceButton}
          access_token={access_token}
          back={() => dispatch({ type: "SET_VIEW", payload: "main" })}
          socket={socket}
        />
      )}
      <Back onEndSession={onEndSession} view={view} show={view !== "main"} />
    </div>
  )
}
