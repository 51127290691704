export default function reducer(state, { payload, type }) {
  switch (type) {
    case "SAVE_TOKEN":
      return {
        ...state,
        access_token: payload.accessToken,
      }
    case "UNABLE_TO_GET_TOKEN":
      return {
        ...state,
        error: "Unable to receive token",
      }
    case "SET_VIEW":
      return {
        ...state,
        // view: state.isEndingSession ? "main" : payload,
        view: payload,
        isEndingSession: false,
        isWaitingUserResponse: false,
        isSayJoke: false, // trigger only when End Session is clicked
      }
    case "SET_FIRST_RENDER":
      return {
        ...state,
        isFirstRender: false,
      }
    case "SAY_JOKE": // triggered by End Session
      return {
        ...state,
        isSayJoke: true,
      }
    case "NEXT_WELCOME_SPEECH":
      console.log(state.currentWelcomeSpeech)
      return {
        ...state,
        currentWelcomeSpeech:
          state.currentWelcomeSpeech === 4 ? 1 : state.currentWelcomeSpeech + 1,
      }

    case "SET_FULLSCREEN_MODE":
      return {
        ...state,
        inFullscreenMode: payload,
      }
    case "SET_WAITING_USER_RESPONSE_TIMER":
      return {
        ...state,
        isWaitingUserResponse: true,
      }
    case "FINISH_WAITING_USER_RESPONSE":
      console.log("FINISH_WAITING_USER_RESPONSE", state.isWaitingUserResponse)
      return {
        ...state,
        ...(() => {
          if (state.isWaitingUserResponse) {
            return {
              view: "main",
              isWaitingUserResponse: false,
              isEndingSession: false,
            }
          }
          return
        })(),
      }
    case "SET_OPTIONS":
      return {
        ...state,
        view: state.isEndingSession ? "main" : "options",
        //view: "options",
        isWaitingUserResponse: false,
        isEndingSession: false,

        containedOptions: payload,
        optionsList: (() =>
          state.buttons.filter((item) => payload.indexOf(item.id) !== -1))(),
      }
    case "SOCKET_CONNECTED_TOGGLE":
      return {
        ...state,
        webSocketIsConnected: !state.webSocketIsConnected,
      }
    case "END_SESSION": // End Session button
      return {
        ...state,
        view: "main",
        isEndingSession: true,
        isWaitingUserResponse: false,
      }

    default:
      console.info("Wrong action")
      return state
  }
}
