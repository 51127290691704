import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"

import Img_Back from "../../../../images/img_back"
import ButtonMinimal from "../../../buttonTransparent"

const BackToMain = ({ back }) => (
  <div
    onClick={back}
    css={css`
      cursor: pointer;
      margin: 0;
      padding: 0;
      background-color: rgba(0, 0, 0, 0);
      display: flex;
      align-items: center;
      border: 0;
      position: absolute;
      top: 24px;
      left: 24px;
      color: white;
      font-size: 32px;
      font-weight: 600;
    `}
  >
    <ButtonMinimal onClick={() => console.log("Back to main")}>
      <Img_Back />
      {/* Back to Main */}
    </ButtonMinimal>
  </div>
)

BackToMain.propTypes = {
  back: PropTypes.func,
}

export default BackToMain
