import React from "react"
//import PropTypes from "prop-types"

import { css } from "@emotion/react"
import { Player } from "@lottiefiles/react-lottie-player"
import alert from "./../lottie/alert.json"

const Disconnected = (props) => {
  return (
    <div
      onClick={() => location.reload()}
      css={css`
        user-select: none;
        position: relative;
        cursor: pointer;
        width: 400px;
        height: 400px;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        color: white;
        opacity: 0.9;
        font-size: 32px;
        font-weight: 600;
        transition: opacity 0.15s ease;
      `}
    >
      <span
        css={css`
          width: 100%;
          text-align: center;
          display: flex;
          text-align: center;
          justify-content: center;
          z-index: 1;
          margin: 0;
          padding: 0;
          background-color: rgba(0, 0, 0, 0);
          border: 0;
          position: absolute;
          display: flex;
          align-items: center;
          bottom: 0%;
          color: white;
          opacity: 0.6;
          font-size: 24px;
          font-weight: 600;
        `}
      >
        No connection with server
      </span>
      <Player
        autoplay
        loop
        src={alert}
        style={{ height: "400px", width: "400px" }}
      />
    </div>
  )
}

Disconnected.propTypes = {}

export default Disconnected
