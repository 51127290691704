import React from "react"
import PropTypes from "prop-types"
import GoogleLogin from "react-google-login"
import { css } from "@emotion/react"

// const clientId =
//   "81810572394-6dbc3c82k27m74rbg7b2fjecci0m1fcm.apps.googleusercontent.com"

const Login = ({ onSuccess, onFailure, clientId }) => {
  return (
    <div
      css={css`
        z-index: 1;
        position: absolute;
        display: flex;
        align-items: center;
        top: 24px;
        left: 47%;
      `}
    >
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  )
}

Login.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export default Login

// authorizationUrl: "https://accounts.google.com/o/oauth2/auth",
// responseType: "token",
// clientId:
//   "81810572394-6dbc3c82k27m74rbg7b2fjecci0m1fcm.apps.googleusercontent.com",
// redirectUri: "http://localhost:1234/authorize/",
// scope: "https://www.googleapis.com/auth/dialogflow",
