import React from "react"

const Img_mic = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fff"
        d="M12 2c1.654 0 3 1.346 3 3v6c0 1.654-1.346 3-3 3s-3-1.346-3-3V5c0-1.654 1.346-3 3-3zm0-2C9.25 0 7 2.25 7 5v6c0 2.75 2.25 5 5 5s5-2.25 5-5V5c0-2.75-2.25-5-5-5z"
      />
      <path
        fill="#fff"
        d="M21 9v2c0 4.61-3.52 8.44-8 8.94V22h4c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1h4v-2.06c-4.48-.5-8-4.33-8-8.94V9c0-.55.45-1 1-1s1 .45 1 1v2c0 3.86 3.14 7 7 7s7-3.14 7-7V9c0-.55.45-1 1-1s1 .45 1 1z"
      />
    </svg>
  )
}

export default Img_mic
