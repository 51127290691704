export default function reducer(state, { payload, type }) {
  switch (type) {
    case "HANDLE_INPUT":
      return {
        ...state,
        input: payload,
        avatarResponse: "",
        submitedInput: "",
        // avatarResponse: state.input === "" ? state.avatarResponse : "",
        // submitedInput: state.input === "" ? state.submitedInput : "",
      }
    case "SUBMIT_VALUE":
      return {
        ...state,
        isSubmiting: true,
        avatarResponse: "",
        submitedInput: state.input,
        input: "",
        error: "",
      }
    case "SHOW_RESOPNSE":
      return {
        ...state,
        isSubmiting: false,
        avatarResponse: payload,
      }
    case "AUDIO_RESOPNSE":
      return {
        ...state,
        avatarResponse: payload.avatarResponse,
        submitedInput: payload.submitedInput,
      }
    case "SUBMIT_ERROR":
      return {
        ...state,
        isSubmiting: false,
        avatarResponse: "",
        submitedInput: "",
        input: state.submitedInput,
        error: payload,
      }
    case "TOGGLE_RECORD":
      return {
        ...state,
        avatarResponse: "",
        submitedInput: "",
        error: "",
        record: payload,
      }
    case "SAVE_AUDIO":
      return {
        ...state,
        audioObject: payload,
        record: false,
      }
    case "SET_RENDER":
      return {
        ...state,
        didRender: true,
      }

    default:
      console.info("Wrong action")
      return state
  }
}
