import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import Img_fullscreen from "./../images/img_fullscreen"
import ButtonMinimal from "./buttonTransparent"
import Img_chat from "./../images/img_chat"

const ControlPanel = ({
  toggleFullscreen,
  inFullscreenMode,
  view,
  showChat,
  showChatButton,
}) => {
  const isApple = /Macintosh|iPhone|iPad|iPod/i.test(navigator.userAgent)
  return (
    <div
      css={css`
        z-index: 1;
        position: absolute;
        display: flex;
        align-items: center;
        top: 24px;
        right: 24px;
        /* margin: 0;
        padding: 0;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
      
        color: white;
        opacity: 0.6;
        font-size: 18px;
        font-weight: 600;
        transition: opacity 0.15s ease;
        &:hover {
          transition: opacity 0.15s ease;
          opacity: 0.8;
        }
        &:active {
          transition: opacity 0.15s ease;
          opacity: 1;
        } */
      `}
    >
      {!inFullscreenMode && !isApple && (
        <ButtonMinimal onClick={toggleFullscreen}>
          <Img_fullscreen />
        </ButtonMinimal>
      )}
      {!(view === "chat" || view === "speaking") && showChatButton && (
        <ButtonMinimal style={{ marginLeft: 16 }} onClick={showChat}>
          <Img_chat />
        </ButtonMinimal>
      )}
      {/* 
      <button onClick={() => setState("main")}>main</button>
      <button onClick={() => setState("speaking")}>speaking</button>
      <button onClick={() => setState("waiting")}>waiting</button>
      <button onClick={() => setState("options")}>options</button> */}
    </div>
  )
}

ControlPanel.propTypes = {}

export default ControlPanel
