import React from "react"

const Img_Reset = () => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={64} height={64} rx={32} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4577 12.8034C22.1538 13.4957 22.1538 14.6181 21.4577 15.3104L17.3703 19.3751H36.2406C45.1013 19.3751 52.2841 26.5182 52.2841 35.3296C52.2841 44.1412 45.1013 51.2842 36.2406 51.2842H21.9798C20.9953 51.2842 20.1972 50.4904 20.1972 49.5115C20.1972 48.5326 20.9953 47.7387 21.9798 47.7387H36.2406C43.1322 47.7387 48.7189 42.183 48.7189 35.3296C48.7189 28.4763 43.1322 22.9205 36.2406 22.9205H17.3703L21.4577 26.9852C22.1538 27.6775 22.1538 28.7999 21.4577 29.4922C20.7615 30.1845 19.6328 30.1845 18.9367 29.4922L11.8063 22.4013C11.1101 21.709 11.1101 20.5866 11.8063 19.8943L18.9367 12.8034C19.6328 12.1111 20.7615 12.1111 21.4577 12.8034Z"
      fill="#230871"
    />
  </svg>
)

export default Img_Reset
