const jquery = require("jquery")
window.$ = window.jQuery = jquery

import { createRoot } from "react-dom/client"
import App from "./app"

const socketEnvLink =
  process.env.API_PROD === "true"
    ? "wss://localhost:10000/avatar/api"
    : "ws://localhost:8080"

console.log("process.env.API_PROD: ", process.env.API_PROD)
console.log("Link: ", socketEnvLink)

const socket = new WebSocket(socketEnvLink)
createRoot(document.getElementById("root")).render(
  <App socket={socket} socketEnvLink={socketEnvLink} />
)
