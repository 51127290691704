import { findOption } from "./findOption"

const sayJoke = (socket) => {
  const randomIntFromInterval = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min)
  // min and max included
  const rndInt = randomIntFromInterval(231, 233)
  console.log("Say random joke #", rndInt)
  const endSessionPhrase = findOption(rndInt)
  process.env.API_PROD === "true"
    ? socket.send(JSON.stringify(endSessionPhrase))
    : console.log("request", endSessionPhrase)
}

export default sayJoke
