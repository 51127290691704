import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import Fade from "@mui/material/Fade"

const TextBuble = ({ text, isUser, isSubmiting }) => {
  if (isSubmiting && !isUser) {
    return "Sending..."
  }

  return (
    <Fade in={text !== ""}>
      <div
        css={css`
          padding-left: ${isUser ? "64px" : "0px"};
          padding-right: ${isUser ? "0px" : "64px"};
        `}
      >
        <div
          css={css`
            cursor: pointer;
            margin-bottom: 24px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            position: relative;
            padding: 24px;
            background-color: ${isUser ? "#25b7b7" : "#333"};
            border: 1px solid ${isUser ? "#155454" : "#4c4a4a"};
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            opacity: 0.9;
            font-size: 24px;
            transition: opacity 0.15s ease;
          `}
        >
          {text}
        </div>
      </div>
    </Fade>
  )
}

TextBuble.propTypes = {}
TextBuble.defaultProps = {
  isSubmiting: false,
}

export default TextBuble
