import React, { useEffect, useState } from "react"
//import React, { useEffect, useState } from "react"
import useRecorder from "./useRecorder"
import VoiceButton from "./voiceButton"

const VoiceRecorder = ({ record, setRecord, saveAudio, isSubmiting }) => {
  const [audioURL, isRecording, startRecording, stopRecording] = useRecorder()

  useEffect(() => {
    if (Object.keys(audioURL).length !== 0) {
      //  console.log("audio", audioURL.blob)
      saveAudio(audioURL.blob)
    }
  }, [audioURL])

  function Buttonstart() {
    console.log("Buttonstart!")
    setRecord(true)
    startRecording()
  }
  async function Buttonstop() {
    if (isRecording === true) {
      await stopRecording()
      console.log("stopRecording!")
    }
    // } else {
    //   alert("Give permission audio to record")
    // }
  }

  return (
    <div>
      {/* {Object.keys(audioURL).length === 0 ? null : (
        <audio src={audioURL.data} controls type="audio/mp3" />
      )} */}
      <VoiceButton
        record={record}
        disabled={isSubmiting}
        onTouchStart={() => setTimeout(() => Buttonstart(), 500)}
        onMouseDown={() => setTimeout(() => Buttonstart(), 500)}
        onBlur={() => setTimeout(() => Buttonstop(), 500)}
        onMouseUp={() => setTimeout(() => Buttonstop(), 500)}
      />
      {/* <span style={{ marginLeft: "20px" }}>
                <TimerController record={record} />
              </span> */}
    </div>
  )
}

export default VoiceRecorder
