import React from "react"
import { css } from "@emotion/react"
import Fade from "@mui/material/Fade"

import PropTypes from "prop-types"
import Img_Reset from "../images/img_reset"
import ButtonMinimal from "./buttonTransparent"

const ResetSession = ({ onEndSession, show, view }) => {
  if (view === "chat") {
    return null
  }
  return (
    <Fade in={show}>
      <div
        css={css`
          margin: 0;
          padding: 0;
          background-color: rgba(0, 0, 0, 0);
          align-items: center;
          border: 0;
          position: absolute;
          bottom: 24px;
          right: 24px;
          color: white;
          font-size: 32px;
          font-weight: 600;
        `}
      >
        <ButtonMinimal onClick={onEndSession}>
          <span
            css={css`
              margin-right: 24px;
              color: white;
              font-size: 32px;
              font-weight: 600;
            `}
            style={{ marginRight: 24 }}
          >
            End Session
          </span>
          <Img_Reset />
        </ButtonMinimal>
      </div>
    </Fade>
  )
}

ResetSession.propTypes = {
  onEndSession: PropTypes.func,
}

export default ResetSession
