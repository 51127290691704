import { buttonsState } from "./../state/buttonsState"

const buttons = buttonsState.buttons
export const findOptionById = (id) => buttons.find((button) => button.id === id)
export const findOption = (id) => {
  const option = findOptionById(id)
  if (option.newVoiceIndex !== "") {
    return {
      speechText: option.newVoiceIndex,
      command: "TriggerSpeechText",
    }
  }
  return {
    command: "TriggerSpeechText",
    speechText: option.speechText,
  }
}
