import { buttonsState } from "./buttonsState"

export const initialState = {
  ...buttonsState,
  clientId:
    "81810572394-6dbc3c82k27m74rbg7b2fjecci0m1fcm.apps.googleusercontent.com",
  isTestMode: false, // Show / Hide verision, button numbers etc.
  showChatButton: true, // Show / Hide chat button
  showLoginButton: true,
  showVoiceButton: false,
  isSayJoke: false,
  view: "main", // chat, speaking, disconnected, options, main
  inFullscreenMode: false,
  currentWelcomeSpeech: 0, // 1 - 4 // 0 - because of
  welcomeSpeechInterval: 60000,
  isWaitingUserResponse: false, // true when options are displayd
  userResponseTimeout: 120000, // 300000 = 5 minutes // 180000 = 3 min // 120000 = 2 min
  isFirstRender: true,
  isEndingSession: false, // interfere speech by pressing End Session
  webSocketIsConnected: false, // show 'disconnected state'
  containedOptions: [5, 6, 7, 8],
  optionsList: [],
  startDialog: {
    id: 0,
    speech: "",
  },
  dialogs: [],
  access_token: "",
  token_type: "Bearer",
}
