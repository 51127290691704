import React from "react"
const Img_fullscreen = () => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 32C0 14.3269 14.3269 0 32 0V0C49.6731 0 64 14.3269 64 32V32C64 49.6731 49.6731 64 32 64V64C14.3269 64 0 49.6731 0 32V32Z"
      fill="white"
    />
    <path
      d="M26 14H14V26M50 26V14L38 14"
      stroke="#230871"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M26 50H14V38M50 38V50H38"
      stroke="#230871"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M50 14L36.5 27.5"
      stroke="#230871"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M14 50L27.5 36.5"
      stroke="#230871"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
)

export default Img_fullscreen
