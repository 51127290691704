import React from "react"
import PropTypes from "prop-types"
import { useReducer, useEffect } from "react"
import { css } from "@emotion/react"

import BackToMain from "./modules/backToMain"
import TextBuble from "./modules/textBuble"
import Input from "./modules/input"
import Error from "./modules/error"
import settings from "./request/request"
import audioSettings from "./request/audio"

import { initialState } from "./initialState"
import reducer from "./reducer"

const Chat = ({ back, socket, access_token, showVoiceButton }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    submitedInput,
    input,
    avatarResponse,
    isSubmiting,
    error,
    record,
    audioObject,
    didRender,
  } = state

  const sendRecording = (blob) => {
    let reader = new window.FileReader()
    reader.readAsDataURL(blob)
    reader.addEventListener("loadend", function () {
      const audioBase64 = reader.result.toString()
      const audioTurned = audioBase64.substring(audioBase64.indexOf(",") + 1)
      $.ajax(audioSettings(audioTurned, `Bearer ${access_token}`)).then(
        ({ queryResult }) => {
          dispatch({
            type: "AUDIO_RESOPNSE",
            payload: {
              avatarResponse: queryResult.fulfillmentText,
              submitedInput: `🎤 ${queryResult.queryText}`,
            },
          })
          socket.send(
            JSON.stringify({
              command: "TriggerSpeechText",
              speechText: queryResult.fulfillmentText,
            })
          )
        },
        ({ responseJSON, readyState }) => {
          if (readyState === 0) {
            console.info("YOU ARE OFFLINE")
            dispatch({
              type: "SUBMIT_ERROR",
              payload: "No Internet connection",
            })
            return
          }
          const { code, message } = responseJSON.error
          dispatch({ type: "SUBMIT_ERROR", payload: `😢 ${code}: ${message}` })
          console.log(responseJSON.error.code, responseJSON.error.message)
        }
      )
    })
  }

  useEffect(() => {
    dispatch({ type: "SET_RENDER" })
    if (didRender) {
      if (!record) {
        console.log("Send recording")
        sendRecording(audioObject)
      }
    }
  }, [record])

  const submit = () => {
    console.log("Submit")
    dispatch({ type: "SUBMIT_VALUE" })
    console.log("key:", `Bearer ${access_token}`)
    $.ajax(settings(input, `Bearer ${access_token}`)).then(
      ({ queryResult }) => {
        dispatch({
          type: "SHOW_RESOPNSE",
          payload: queryResult.fulfillmentText,
        })
        socket.send(
          JSON.stringify({
            command: "TriggerSpeechText",
            speechText: queryResult.fulfillmentText,
          })
        )
      },
      ({ responseJSON, readyState }) => {
        if (readyState === 0) {
          console.info("YOU ARE OFFLINE")
          dispatch({ type: "SUBMIT_ERROR", payload: "No Internet connection" })
          return
        }
        const { code, message } = responseJSON.error
        dispatch({ type: "SUBMIT_ERROR", payload: `${code}: ${message}` })
        console.log(responseJSON.error.code, responseJSON.error.message)
      }
    )
  }
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 640px;
        max-width: 640px;
        height: 100vh;
      `}
    >
      <BackToMain back={back} />
      <div
        css={css`
          padding-top: 80px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 640px;
          max-width: 640px;
          height: 400px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 640px;
            max-width: 640px;
          `}
        >
          <TextBuble text={submitedInput} isUser isSubmiting={isSubmiting} />
          <TextBuble text={avatarResponse} isSubmiting={isSubmiting} />
          {error !== "" && <Error error={error} />}
        </div>
        {record && showVoiceButton && (
          <span
            css={css`
              display: flex;
              width: 640px;
              padding: 16px;
              align-items: center;
              justify-content: center;
            `}
          >
            Speak...
          </span>
        )}
        <Input
          showVoiceButton={showVoiceButton}
          setRecord={(value) =>
            dispatch({ type: "TOGGLE_RECORD", payload: value })
          }
          saveAudio={(value) =>
            dispatch({ type: "SAVE_AUDIO", payload: value })
          }
          record={record}
          isSubmiting={isSubmiting}
          submit={submit}
          input={input}
          handleInput={(value) =>
            dispatch({ type: "HANDLE_INPUT", payload: value })
          }
        />
      </div>
    </div>
  )
}

Chat.propTypes = {
  back: PropTypes.func,
}

export default Chat
