import { welcomeSpeechState } from "./welcomeSpeechState"
const delay = 5000
const LongDelay = 10000

export const buttonsState = {
  buttons: [
    {
      id: 0,
      newVoiceIndex: "00",
      text: "",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText: "Choose from the options below.",
      containedOptions: [5, 7, 8, 9],
      speechDelay: delay,
    },
    ...welcomeSpeechState.welcomeSpeech, // id 1 - 4
    {
      id: 5,
      newVoiceIndex: "05",
      text: "Who are you?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "I'm a digital human, capable of social interactions upon text or speech input.",
      containedOptions: [6, 9, 10, 14],
      speechDelay: 6000,
    },
    {
      id: 6,
      newVoiceIndex: "06",
      text: "Are you a real person?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "No, I'm a real digital human, computer generated, AI powered.",
      containedOptions: [5, 7, 8, 9],
      speechDelay: 6000,
    },
    {
      id: 7,
      newVoiceIndex: "07",
      text: "What can you do?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "I can pitch products and concepts, meet and greet customers, provide instructions and navigation.",
      containedOptions: [11, 12, 13, 14],
      previousExeption: 10,
      speechDelay: 8000,
    },
    {
      id: 8,
      newVoiceIndex: "08",
      text: "What is HYPERVSN?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Hyper vision is an integrated 3D display technology platform which allows users to project 3D visuals as if they're floating in mid-air, and interact with holographic content.",
      containedOptions: [10, 16, 17, 18],
      previousExeption: 0,
      speechDelay: 12000,
    },
    {
      id: 9,
      newVoiceIndex: "09",
      text: "How do you function?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "I'm a digital human, AI powered and computer generated. I'm a part of the Hyper vision holographic human solution. I can act as a physical embodiment of your Alexa or Siri, and I can be pre-configured against any chat-bot, to allow for real-time interaction.",
      containedOptions: [7, 10, 14, 15],
      previousExeption: 10,
      speechDelay: 20000,
    },
    {
      id: 10,
      newVoiceIndex: "10",
      text: "What are HYPERVSN Holographic humans?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Hyper vision provides a universe of holographic and digital humans, both pre-recorded and interactive. You can display a real person as a holographic human, or choose from an extensive metahuman library. Moreover, human content, as well as AI generated avatar  can be interactive, configured with a chat-bot, and used with voice recognition and speech-to-text modules.",
      containedOptions: [6, 7, 9, 15],
      speechDelay: 25000,
    },
    {
      id: 11,
      newVoiceIndex: "11",
      text: "Pitch products",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Here's a sample: Hyper vision is so much more than just a piece of extraordinary hardware, it's a fully integrated system of management, support & 3D content creation - producing unparalleled digital excellence.",
      containedOptions: [6, 14, 15, 19],
      speechDelay: 13000,
    },
    {
      id: 12,
      newVoiceIndex: "12",
      text: "Meet and greet",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Here's a sample: Hello, it's great to see you in our store again. We hope that you enjoyed your last purchase! We have a great promo for you today! Get 20% off cushion and bed linen. And don't miss our new collection of slipcovers - our shoppers' top choice this season!",
      containedOptions: [6, 14, 15, 19],
      speechDelay: 20000,
    },
    {
      id: 13,
      newVoiceIndex: "13",
      text: "Give instructions",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Here's a sample: Good day, your room number is 302, and you can claim your electronic key at the reception desk on the right. Let me guide you through the relaxing world of our spah services and facilities.",
      containedOptions: [6, 14, 15, 19],
      speechDelay: 15000,
    },
    {
      id: 14,
      newVoiceIndex: "14",
      text: "Tell me about HYPERVSN's product range",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "HYPERVSN's Integrated 3D Holographic Display System offers two distinct products to choose from, depending on what size 3D holographic visual your business or advertising campaign requires: Solo for up to 75cm diameter and Wall for virtually any size and configuration. Apart from the main products, there are interactive Hyper vision solutions, including 3D catalogue, Hyper vision Slots, 3D modeller, Hyper vision holographic and meta humans.",
      containedOptions: [16, 17, 18, 20],
      speechDelay: 30000,
    },
    {
      id: 15,
      newVoiceIndex: "15",
      text: "How do I purchase a HYPERVSN product?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "To buy or rent Hyper vision products, please ask my human teammates at the Hyper vision booth.",
      containedOptions: [6, 7, 14, 19],
      speechDelay: 6000,
    },
    {
      id: 16,
      newVoiceIndex: "16",
      text: "What is a HYPERVSN Solo?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Hyper vision Solo is a revolutionary solution to create, manage and display brilliantlly bright 3D content, which appears to be floating in mid-air. The single display is up to 75 cm in size and is simply managed through the Content Management System.",
      containedOptions: [15, 19, 20, 22],
      speechDelay: 18000,
    },
    { 
      id: 17,
      newVoiceIndex: "17",
      text: "What is a HYPERVSN Wall",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "With the Hyper vision Wall you can produce high-resolution images of almost any size, by combining as many devices as you want. Hyper vision Walls can be installed vertically or horizontally to fit your specific space and brand requirements. The modular frame allows for easy installation and maintenance, and can be scaled to virtually any size.",
      containedOptions: [15, 19, 22, 20],
      speechDelay: 23000,
    },
    {
      id: 18,
      newVoiceIndex: "18",
      text: "What about accessories?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "With our range of Accessories and installation solutions, you can create the perfect holographic display in any environment. Whether you are looking for a protective screen, an alternative mounting solution or just a quicker way to install a Hyper vision Solo or Wall, our range has you covered.",
      containedOptions: [14, 15, 17, 19],
      speechDelay: 18000,
    },
    {
      id: 19,
      newVoiceIndex: "19",
      text: "Can I rent your products?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "If you want to rent Hyper vision products, please ask any of the Hyper vision humans.",
      containedOptions: [5, 7, 9],
      speechDelay: delay,
    },
    {
      id: 20,
      newVoiceIndex: "20",
      text: "What is HYPERVSN CMS",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Hyper vision CMS includes comprehensive software and services that manage Hyper vision devices and content. The system consists of web-based functionality and a downloadable mobile App. The functionality of Hyper vision CMS can be extended with the add-on SmartV Pro subscription service, offered at a monthly fee.",
      containedOptions: [5, 7, 8, 9],
      speechDelay: 20000,
    },

    {
      id: 22,
      newVoiceIndex: "22",
      text: "How do I get 3D content to display?",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "You can create content yourself using our user-friendly 3D Studio tool, use pre-made animations found in our Media Library, or order an animation of any complexity from our amazing in-house Design Lab team.",
      containedOptions: [5, 7, 8, 9],
      speechDelay: 16000,
    },
    {
      id: 23,
      newVoiceIndex: "23-0",
      text: "Good-bye",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "It's a pity to see you leave so early. Here's a joke to lighten your day.",
      containedOptions: [],
      speechDelay: LongDelay,
    },
    {
      id: 231,
      newVoiceIndex: "23-1",
      text: "End Session",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "Why are artificial intelligences in movies always female? Because they're never wrong.",
      containedOptions: [],
      speechDelay: LongDelay,
    },
    {
      id: 232,
      newVoiceIndex: "23-2",
      text: "End Session",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText:
        "What do you call a program that identifies spa treatments? Facial recognition!",
      containedOptions: [],
      speechDelay: LongDelay,
    },
    {
      id: 233,
      newVoiceIndex: "23-3",
      text: "End Session",
      gesture: "gesture",
      command: "TriggerSpeechText",
      speechText: "What is an AI's favourite music?: Algorhythms.",
      containedOptions: [],
      speechDelay: LongDelay,
    },
  ],
}
