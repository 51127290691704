import React from "react"
//import PropTypes from "prop-types"

import { css } from "@emotion/react"
import { Player } from "@lottiefiles/react-lottie-player"
import connecting from "./../lottie/connecting.json"

const Connecting = (props) => {
  return (
    <div
      css={css`
        position: relative;
        width: 400px;
        height: 400px;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        color: white;
        opacity: 0.9;
        font-size: 32px;
        font-weight: 600;
        transition: opacity 0.15s ease;
      `}
    >
      <span
        css={css`
          z-index: 1;
          margin: 0;
          padding: 0;
          background-color: rgba(0, 0, 0, 0);
          border: 0;
          position: absolute;
          display: flex;
          align-items: center;
          bottom: 46%;
          right: 30%;
          color: white;
          opacity: 0.6;
          font-size: 24px;
          font-weight: 600;
        `}
      >
        Connecting...
      </span>
      <Player
        autoplay
        loop
        src={connecting}
        style={{ height: "400px", width: "400px" }}
      />
    </div>
  )
}

//Connecting.propTypes = {}

export default Connecting
