import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import Fade from "@mui/material/Fade"
import { Player } from "@lottiefiles/react-lottie-player"

import empty from "../lottie/empty"
const ListItem = ({
  text,
  requestOption,
  id,
  containedOptions,
  isTestMode,
  speechDelay,
}) => {
  return (
    <li
      onClick={requestOption}
      css={css`
        margin-bottom: 24px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        position: relative;
        /* width: 100%; */
        padding: 24px;
        background-color: rgba(256, 256, 256, 1);
        border: 1px solid gray;
        border-radius: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
        opacity: 0.8;
        font-size: 24px;
        transition: opacity 0.15s ease;
        &:hover {
          transition: opacity 0.15s ease;
          opacity: 0.9;
        }
        &:active {
          transition: opacity 0.15s ease;
          opacity: 1;
        }
      `}
    >
      {text}
      {isTestMode && (
        <span
          css={css`
            color: gray;
            font-size: 16px;
          `}
        >
          {id} | {containedOptions.join(", ")} | {speechDelay}
        </span>
      )}
    </li>
  )
}
const Options = ({ emptyList, currentList, requestOption, isTestMode }) => {
  if (!currentList.length) {
    emptyList()
    return (
      <Player
        autoplay
        loop
        src={empty}
        style={{ height: "300px", width: "100%" }}
      />
    )
  }
  return (
    <div>
      <Fade in>
        <ul
          css={css`
            width: 640px;
            display: flex;
            flex-direction: column;
            color: white;
          `}
        >
          {currentList.map(
            ({ text, id, containedOptions, speechDelay }, index) => (
              <ListItem
                text={text}
                key={index}
                id={id}
                isTestMode={isTestMode}
                speechDelay={speechDelay}
                containedOptions={containedOptions}
                requestOption={() => requestOption(id)}
              />
            )
          )}
        </ul>
      </Fade>
    </div>
  )
}

Options.propTypes = {}

export default Options
