import React from "react"
//import PropTypes from "prop-types"
import { css } from "@emotion/react"
import VoiceRecorder from "./voice/voiceRecorder"
import Fade from "@mui/material/Fade"
import VoiceInfo from "./voice/voiceInfo"

const Input = ({
  input,
  handleInput,
  submit,
  isSubmiting,
  record,
  setRecord,
  saveAudio,
  showVoiceButton,
}) => {
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
        css={css`
          display: flex;
        `}
      >
        <input
          css={css`
            box-shadow: 0px 4px 4px 0px rgba(0, 70, 100, 0.25);
            width: 100%;
            padding: 8px;
            background-color: rgba(256, 256, 256, 1);
            border: 2px solid lightgray;
            border-radius: 40px;
            color: black;
            font-size: 20px;
            opacity: 1;
            transition: opacity 0.15s ease;
            &:hover {
              transition: opacity 0.15s ease;
              opacity: 0.9;
            }
            &:active {
              transition: opacity 0.15s ease;
              opacity: 1;
              border: 2px solid #00aeff;
            }
            &:focus {
              transition: opacity 0.15s ease;
              opacity: 1;
              border: 2px solid #30b9f9;
              box-shadow: 0px 0px 12px 4px rgba(0, 70, 100, 0.25);
            }
            &:disabled {
              opacity: 0.6;
            }
          `}
          disabled={isSubmiting}
          value={input}
          onChange={(e) => handleInput(e.target.value)}
          placeholder="Ask any question"
        ></input>

        <button
          css={css`
            cursor: pointer;
            /* border: 0; */
            margin-left: 8px;
            font-size: 20px;
            padding: 0px 16px 0px 16px;
            font-weight: 600;
            border-radius: 8px;
            transition: opacity 0.15s ease;
            &:disabled {
              cursor: default;
            }
            &:active {
              transition: opacity 0.15s ease;
              opacity: 0.8;
            }
          `}
          disabled={input === "" || isSubmiting}
          type="submit"
          onClick={submit}
        >
          Send
        </button>
        {showVoiceButton && (
          <VoiceRecorder
            isSubmiting={isSubmiting}
            setRecord={setRecord}
            record={record}
            saveAudio={saveAudio}
          />
        )}
      </form>
      {showVoiceButton && <VoiceInfo isSubmiting={isSubmiting} />}
    </div>
  )
}

Input.propTypes = {}

export default Input
