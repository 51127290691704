{
  "name": "digital_human",
  "version": "0.5.5",
  "packageManager": "yarn@3.2.0",
  "source": "src/index.html",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "author": "HYPERVSN",
  "license": "ISC",
  "scripts": {
    "dev": "parcel",
    "build": "parcel build",
    "json": "json-socket-server db.json",
    "update": "npm-check-updates"
  },
  "dependencies": {
    "@emotion/react": "^11.9.0",
    "@emotion/styled": "^11.8.1",
    "@lottiefiles/react-lottie-player": "^3.4.5",
    "@mui/material": "^5.6.4",
    "fscreen": "^1.2.0",
    "gapi-script": "^1.2.0",
    "jquery": "^3.6.0",
    "react": "^18.1.0",
    "react-dom": "^18.1.0",
    "react-google-login": "^5.2.2"
  },
  "devDependencies": {
    "@babel/core": "^7.17.10",
    "@babel/preset-react": "^7.16.7",
    "@emotion/babel-plugin": "^11.9.2",
    "@emotion/babel-preset-css-prop": "^11.2.0",
    "@parcel/packager-xml": "2.5.0",
    "@parcel/transformer-xml": "2.5.0",
    "babel-preset-env": "^1.7.0",
    "babel-preset-react": "^6.24.1",
    "buffer": "^6.0.3",
    "http-server": "^14.1.0",
    "parcel": "^2.5.0",
    "process": "^0.11.10",
    "prop-types": "^15.8.1"
  }
}
