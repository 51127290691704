import React from "react"
import PropTypes from "prop-types"

import TalkButton from "./modules/talkButton"
import Speaking from "./../../speaking"
import Connecting from "./../../connecting"
import Disconnected from "./../../disconnected"
import { findOptionById, findOption } from "./../../../helpers/findOption"
import { socketSend } from "./../../../helpers/socket"
import Options from "./../../options"

const Main = ({
  view,
  optionsList,
  emptyList,
  socket,
  dispatch,
  isTestMode,
}) => {
  const requestOption = (id) => {
    console.log("Speaking...")
    dispatch({ type: "SET_VIEW", payload: "speaking" })
    const option = findOption(id)
    socketSend(socket, option)
    const optionObject = findOptionById(id)
    setTimeout(
      () =>
        dispatch({
          type: "SET_OPTIONS",
          payload: optionObject.containedOptions,
        }),
      optionObject.speechDelay
    )
  }

  switch (view) {
    case "main":
      return <TalkButton startTalking={() => requestOption(0)} />
    case "speaking":
      return <Speaking />
    case "connecting":
      return <Connecting />
    case "disconnected":
      return <Disconnected />
    case "options":
      return (
        <Options
          isTestMode={isTestMode}
          currentList={optionsList}
          emptyList={emptyList}
          requestOption={requestOption}
        />
      )
    case "waiting":
      return null
    case "chat":
      return null
    default:
      return "Unknown state"
  }
}

Main.propTypes = {
  view: PropTypes.string,
  optionsList: PropTypes.arrayOf(PropTypes.object),
  emptyList: PropTypes.func,
  socket: PropTypes.object,
  dispatch: PropTypes.func,
  isTestMode: PropTypes.bool,
}

export default Main
