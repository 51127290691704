import React from "react"
import { css } from "@emotion/react"

import PropTypes from "prop-types"
import { Player } from "@lottiefiles/react-lottie-player"
import blob from "../../../../lottie/blob.json"

const TalkButton = ({ startTalking }) => {
  return (
    <div
      onClick={() => startTalking()}
      css={css`
        position: relative;
        width: 400px;
        height: 400px;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        cursor: pointer;
        color: white;
        opacity: 0.9;
        font-size: 32px;
        font-weight: 600;
        transition: opacity 0.15s ease;
        &:hover {
          transition: opacity 0.15s ease;
          opacity: 0.95;
        }
        &:active {
          transition: opacity 0.15s ease;
          opacity: 1;
        }
      `}
    >
      <span
        css={css`
          user-select: none;
          z-index: 1;
          margin: 0;
          padding: 0;
          background-color: rgba(0, 0, 0, 0);
          border: 0;
          cursor: pointer;
          position: absolute;
          display: flex;
          align-items: center;
          bottom: 46%;
          right: 30%;
          color: white;
          opacity: 0.6;
          font-size: 32px;
          font-weight: 600;
        `}
      >
        TALK TO ME
      </span>
      <Player
        autoplay
        loop
        src={blob}
        style={{ height: "400px", width: "400px" }}
      />
    </div>
  )
}

TalkButton.propTypes = {
  startTalking: PropTypes.func,
}

export default TalkButton
