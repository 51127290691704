import React from "react"

import packageProps from "./../../package.json"
import { css } from "@emotion/react"
console.log("Version: ", packageProps.version)
const Version = () => (
  <div
    css={css`
      margin: 0;
      padding: 0;
      background-color: rgba(0, 0, 0, 0);
      align-items: center;
      border: 0;
      position: absolute;
      bottom: 24px;
      left: 24px;
      opacity: 0.4;
      color: white;
      font-size: 16px;
    `}
  >
    {packageProps.version}
  </div>
)

export default Version
