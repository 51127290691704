export const welcomeSpeechState = {
  welcomeSpeech: [
    {
      id: 1,
      newVoiceIndex: "01",
      command: "TriggerSpeechText",
      speechText:
        "Hello, it's good to see you, here at our experiental booth. I'm a Hyper vision digital human. Tap \"Talk to Me\" to start a conversation with me.",
    },
    {
      id: 2,
      newVoiceIndex: "02",
      command: "TriggerSpeechText",
      speechText:
        "Welcome to ISE and Hyper vision holographic experiental booth. Look around and discover awe-inspiring 3D visuals! Feel free to ask my human team mates to assist you with Hyper vision solutions, or talk to me about digital humans and other Hyper vision products.",
    },
    {
      id: 3,
      newVoiceIndex: "03",
      command: "TriggerSpeechText",
      speechText:
        "Hyper vision has brought an incredible range of new interactive products to ISE this year. Phenomenally re-defined holographic solos and wall, with brightness up to 3000 nits, capable of HDMI, live streaming and a universe of interactive possibilities.",
    },
    {
      id: 4,
      newVoiceIndex: "04",
      command: "TriggerSpeechText",
      speechText:
        "Our holographic humans have been transformed with new metaverse capabilities. You can choose your digital human from our library or your holographic self can pitch your ideas anywhere on the planet without travelling a mile. Talk to me for more information or ask my human teammates to help.",
    },
  ],
}
