export const initialState = {
  didRender: false, // prevent sending voice whene page is rendered
  isSubmiting: false,
  submitedInput: "",
  input: "",
  avatarResponse: "",
  error: "",
  record: false,
  audioObject: null,
  // submitedInput:
  //   "The Pentagon top brass hates the idea of getting embroiled in Kosovo peacekeeping.",
  // input: "",
  // avatarResponse:
  //   "It's time we were able to lay off these heavy winter clothes",
}
