import React from "react"

const Img_Back = () => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 32C0 14.3269 14.3269 0 32 0V0C49.6731 0 64 14.3269 64 32V32C64 49.6731 49.6731 64 32 64V64C14.3269 64 0 49.6731 0 32V32Z"
      fill="white"
    />
    <path
      d="M33.0607 19.0607C33.6464 18.4749 33.6464 17.5251 33.0607 16.9393C32.4749 16.3536 31.5251 16.3536 30.9393 16.9393L16.9393 30.9393C16.7955 31.0832 16.687 31.2489 16.6138 31.4258C16.5405 31.6027 16.5 31.7966 16.5 32C16.5 32.2034 16.5405 32.3973 16.6138 32.5742C16.6865 32.7498 16.7939 32.9144 16.9362 33.0575"
      fill="#230871"
    />
    <path
      d="M16.9401 33.0614L30.9393 47.0607C31.5251 47.6464 32.4749 47.6464 33.0607 47.0607C33.6464 46.4749 33.6464 45.5251 33.0607 44.9393L21.6213 33.5H46C46.8284 33.5 47.5 32.8284 47.5 32C47.5 31.1716 46.8284 30.5 46 30.5H21.6213L33.0607 19.0607"
      fill="#230871"
    />
  </svg>
)

export default Img_Back
