import React from "react"
//import PropTypes from "prop-types"
import { css } from "@emotion/react"

const ButtonMinimal = ({ children, onClick, ...args }) => {
  return (
    <button
      {...args}
      onClick={onClick}
      css={css`
        user-select: none;
        margin: 0;
        padding: 0;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        opacity: 0.6;
        transition: opacity 0.15s ease;
        &:hover {
          transition: opacity 0.15s ease;
          opacity: 0.8;
        }
        &:active {
          transition: opacity 0.15s ease;
          opacity: 1;
        }
      `}
    >
      {children}
    </button>
  )
}

ButtonMinimal.propTypes = {}
ButtonMinimal.defaultProps = {
  onClick: () => console.log("Click!"),
}

export default ButtonMinimal
