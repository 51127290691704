import React from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import voice from "./../lottie/voice.json"

const Speaking = () => (
  <div style={{ width: "100%" }}>
    <Player
      autoplay
      loop
      src={voice}
      style={{ height: "300px", width: "100%" }}
    />
  </div>
)

export default Speaking
