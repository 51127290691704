import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import Img_mic from "../../../../../images/img_mic"

const VoiceButton = ({ record, ...args }) => {
  return (
    <button
      {...args}
      type="button"
      css={css`
        cursor: pointer;
        border: 0px;
        margin-left: 8px;
        width: 84px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        background-color: ${record ? "#cb2e07" : "#0096b0"};
        &:active {
          transition: opacity 0.15s ease;
          opacity: 0.8;
        }
        &:disabled {
          transition: opacity 0.15s ease;
          background-color: gray;
          opacity: 0.4;
        }
      `}
    >
      <Img_mic />
    </button>
  )
}

VoiceButton.propTypes = {
  record: PropTypes.bool,
}

export default VoiceButton
