import React from "react"

const Img_chat = () => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 32C0 14.3269 14.3269 0 32 0V0C49.6731 0 64 14.3269 64 32V32C64 49.6731 49.6731 64 32 64V64C14.3269 64 0 49.6731 0 32V32Z"
      fill="white"
    />
    <path
      d="M52 39C52 40.1493 51.5435 41.2515 50.7308 42.0641C49.9181 42.8768 48.8159 43.3333 47.6667 43.3333H21.6667L13 52V17.3333C13 16.1841 13.4565 15.0819 14.2692 14.2692C15.0819 13.4565 16.1841 13 17.3333 13H47.6667C48.8159 13 49.9181 13.4565 50.7308 14.2692C51.5435 15.0819 52 16.1841 52 17.3333V39Z"
      stroke="#230871"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.6">
      <circle cx={24} cy={28} r={2} fill="#230871" />
      <circle cx={32} cy={28} r={2} fill="#230871" />
      <circle cx={40} cy={28} r={2} fill="#230871" />
    </g>
  </svg>
)

export default Img_chat
