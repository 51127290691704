const audioSettings = (inputAudio, auth) => ({
  url: "https://dialogflow.googleapis.com/v2/projects/digitalhuman-359ff/agent/sessions/29be7ac4-93e4-842f-ad39-72c840802cd6:detectIntent",
  method: "POST",
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Authorization: auth,
  },
  data: JSON.stringify({
    queryInput: {
      audioConfig: {
        audioEncoding: "AUDIO_ENCODING_LINEAR_16",
        sampleRateHertz: 16000,
        languageCode: "en",
      },
    },
    queryParams: {
      source: "DIALOGFLOW_CONSOLE",
      timeZone: "Europe/Minsk",
      sentimentAnalysisRequestConfig: {
        analyzeQueryTextSentiment: true,
      },
    },
    inputAudio: inputAudio,
  }),
})

export default audioSettings
