//import auth from "./auth"

const settings = (input, auth) => ({
  url: "https://dialogflow.googleapis.com/v2/projects/digitalhuman-359ff/agent/sessions/29be7ac4-93e4-842f-ad39-72c840802cd6:detectIntent",
  method: "POST",
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Authorization: auth,
  },
  data: JSON.stringify({
    queryInput: {
      text: {
        text: input,
        languageCode: "en",
      },
    },
    queryParams: {
      source: "DIALOGFLOW_CONSOLE",
      timeZone: "Europe/Minsk",
      sentimentAnalysisRequestConfig: {
        analyzeQueryTextSentiment: true,
      },
    },
  }),
})

export default settings
