import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import Fade from "@mui/material/Fade"

const Error = ({ error }) => {
  return (
    <Fade in={error !== ""}>
      <span
        css={css`
          border-radius: 16px;
          background-color: #fff1f1d3;
          color: #cb0000;
          padding: 16px 16px;
          margin-bottom: 16px;
        `}
      >
        🚨 {error}
      </span>
    </Fade>
  )
}

Error.propTypes = {
  error: PropTypes.string,
}

export default Error
